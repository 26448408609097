import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  'start.learning': {
    id: 'start.learning',
    defaultMessage: ' Digital Transformation Made Easy',
    description: 'Header text for logistration MFE pages',
  
  },
  'with.site.name': {
    id: 'with.site.name',
    defaultMessage: 'with Eden',
    description: 'Header text with site name for logistration MFE pages',
  },
});

export default messages;
