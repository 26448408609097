import React, { useEffect, useState } from 'react';
import './Navbar.css';
import logo from './newbluelogo.png'; // Adjust the path to your logo image

const Navbar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(scrollPosition > currentScrollPos || currentScrollPos < 10);
      setScrollPosition(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  return (
    <nav className={`navbar ${visible ? 'visible' : 'hidden'}`}>
      <div className="navbar-logo">
        <a href="https://learn.edenmea.com/">
          <img src={logo} alt="Logo" />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
